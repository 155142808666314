import cn from "classnames";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BUTTON_NEXT_ID, BUTTON_BACK_ID } from "~/configs/consts";

import styles from "./CustomButton.module.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "#3d935e" }} spin />;

export const CustomButton = ({
  name,
  style,
  onClick,
  className: cN,
  isLoading,
  type,
  disabled,
  isBackButton = false,
  ...props
}: any) => {
  return (
    <button
      {...props}
      style={style}
      onClick={onClick}
      className={cn(styles.button, cN, {
        [styles.buttonDisabled]: isLoading || disabled
      })}
      id={isBackButton ? BUTTON_BACK_ID : BUTTON_NEXT_ID}
      type={type}
      disabled={disabled}
    >
      {isLoading ? <Spin indicator={antIcon} /> : name}
    </button>
  );
};
