import React from "react";
import { Divider } from "antd";
import { CustomButton } from "~/components";

import styles from "./ErrorPage.module.scss";

export const ErrorPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card} style={{ marginBottom: 20 }}>
        <div className={styles.image} style={{ marginTop: 50 }} />
        <h2 className={styles.titleSuccess} id="title-error">Ой, что-то пошло не так...</h2>
        <div className={styles.containerSubtitle}>
          <p className={styles.subtitleSuccess}>
            Возможно произошел технический сбой, данные переданы в техническую службу.
          </p>
          <CustomButton
            name="Попробовать еще раз"
            className={styles.button}
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
      <div className={styles.card}>
        <h3 className={styles.titleNext}>Что дальше?</h3>
        <Divider style={{ margin: 0 }} />
        <div className={styles.listNext}>
          <div className={styles.itemNext} style={{ marginBottom: 30 }}>
            <div className={styles.numberNext}>
              <p>1</p>
            </div>
            <p className={styles.textNext}>
              Возможно у Вас произошел сбой связи в момент отправки данных анкеты, такое бывает. В
              этом случае придется заполнять данные анкеты заново.
            </p>
          </div>
          <div className={styles.itemNext} style={{ marginBottom: 30 }}>
            <div className={styles.numberNext}>
              <p>2</p>
            </div>
            <p className={styles.textNext}>
              В любом случае данные сессии были переданы в нашу техническую службу. Поэтому наши
              специалисты обязательно свяжутся с вами для помощи.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
