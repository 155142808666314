import { Divider } from "antd";

import styles from "./SuccessPage.module.scss";

interface Props {
  isPartner?: boolean;
}

const renderContentCustomer = () => {
  return (
    <div className={styles.listNext}>
      <div className={styles.itemNext} style={{ marginBottom: 30 }}>
        <div className={styles.numberNext}>
          <p>1</p>
        </div>
        <p className={styles.textNext}>
          В течение одного дня на электронную почту собственника бизнеса, указанную в данной анкете,
          придет ссылка на оплату подключения
        </p>
      </div>
      <div className={styles.itemNext} style={{ marginBottom: 30 }}>
        <div className={styles.numberNext}>
          <p>2</p>
        </div>
        <p className={styles.textNext}>
          После оплаты подключения в течение дня на эту же электронную почту придет доступ к личному
          кабинету + ссылка на инструкции по работе с системой + документы на подпись. Внимание! На
          этом этапе система будет в демо-режиме
        </p>
      </div>
      <div className={styles.itemNext}>
        <div className={styles.numberNext}>
          <p>3</p>
        </div>
        <p className={styles.textNext}>
          После получения Продамусом ответного письма со сканами (либо качественными фото)
          документов личный кабинет будет переведен в рабочий режим
        </p>
      </div>
    </div>
  );
};

const renderContentPartner = () => {
  return (
    <div className={styles.listNext}>
      <div className={styles.itemNext} style={{ marginBottom: 30 }}>
        <div className={styles.numberNext}>
          <p>1</p>
        </div>
        <p className={styles.textNext}>
          В течение одного рабочего дня проверим данные, активируем промокод и свяжемся с вами.
          Ожидайте звонка или сообщения от менеджера Продамус.
        </p>
      </div>
    </div>
  );
};

export const SuccessPage = ({ isPartner }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.card} style={{ marginBottom: 20 }}>
        <div className={styles.image} style={{ marginTop: 50 }} />
        <h2 className={styles.titleSuccess} id="title-success">Анкета отправлена!</h2>
        <div className={styles.subtitleSuccess} style={{ marginBottom: 50 }}>
          <span>На ваш email было отправлено письмо.</span>
          <span>Для продолжения пройдите по ссылке в нем.</span>
        </div>
      </div>
      <div className={styles.card}>
        <h3 className={styles.titleNext}>Что дальше?</h3>
        <Divider style={{ margin: 0 }} />
        {isPartner ? renderContentPartner() : renderContentCustomer()}
        <Divider style={{ margin: 0 }} />

        <p className={styles.textEnd}>
          Можно будет принимать оплаты. Данные шаги будут дублироваться в приходящих на электронную
          почту письмах, так что вы точно ничего не пропустите. Желаем успешного использования
          системы! Надеемся, что сотрудничество принесет Вам только положительные эмоции и поможет
          приумножить ваши доходы!
        </p>
      </div>
    </div>
  );
};
